import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  toolbar: {
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: theme.spacing(5),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  menuSection: {
    flex: "auto",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    [theme.breakpoints.down('md')]: {
      display:"inline"
    },
  },
  alert: {
    padding: "30px 100px",
    fontSize: "1.4rem",
    "& > div > svg": {
      fontSize: "2.4rem"
    }
  },
  buttonSection: {
    // minWidth: 270,
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobileMenuSection: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  brand: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    cursor: "pointer",
    marginBottom: 10,
    [theme.breakpoints.down(1348)]: {
      marginBottom: 20,
    },
  },
  logo: {
    width: 250,
    maxWidth: 600,
    marginBottom: 0,
  },
  navigation: {
    display: "flex",
    alignItems: "center",
    margin: 0,
    paddingLeft: 0,
    backgroundColor: "#fff",
    listStyle: "none",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  navItem: {
    padding: 0,
    marginRight: 10,
    [theme.breakpoints.down("sm")]: {
      margin: "10px 20px",
      justifyContent: "center",
    },
    "& > a": {
      padding: "5px 15px"
    },
    "&:hover": {
      background: "none",
    },
  },
  navLink: ({ isactive }: any) => ({
    color: isactive === "true" ? "#25447b" : "#6b8792",
    fontFamily: isactive === "true" ? "Roboto Bold" : "Roboto Medium",
    fontSize: "1.25rem",
    letterSpacing: 1,
    textDecoration: "none",
    textTransform: "uppercase",
    transition: "color 0.3s",
    "&:hover":  {
      color: "#25447b",
    }
  }),
  subMenuButton:{
    color: "#6b8792",
    fontFamily: "Roboto Medium",
    fontSize: "1.25rem",
    letterSpacing: 1,
    textDecoration: "none",
    textTransform: "uppercase",
    transition: "color 0.3s",
    "&:hover":  {
      color: "#25447b",
      cursor: "pointer"
    }
  },
  subMenu: {
    "& > li > a": {
      fontSize: "1rem"
    }
  },
  subMenuItem: {
    fontSize: "1rem",
    textDecoration: "none"
  },
  actionButton: {
    color: "#27a1e1",
    fontSize: "1rem",
    textTransform: "none",
    padding: "2px 20px",
    marginLeft: 20,
    background: "none",
    border: "1px solid #89c5c3",
    "&:hover": {
      color: "#25447b",
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: 10,
    },
  },
  contactUsButton: {
    color: "#fff",
    fontSize: "1rem",
    marginLeft: 15,
    [theme.breakpoints.down("md")]: {
      marginBottom: 10,
    },
  }
}))
