import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    flexGrow: 1,
    //maxWidth: 1920,
    justifyContent: "center",
    backgroundColor: "#2d65b2",
    padding: "30px 60px 20px 60px",
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  itemWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  footerHead: {
    color: "#fff",
    marginBottom: 10,
    fontSize: "1.5rem",
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.25rem",
    },
  },
  footerText: {
    color: "#fff",
    marginBottom: 10,
    fontSize: "1rem",
    lineHeight: "1.25rem",
    [theme.breakpoints.up("xl")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
    },
  },
  logo: {
    width: 250,
    maxWidth: 600,
    marginBottom: 30,
  },
  itemLink: {
    color: "#fff",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    }
  },
  footerLink:{
    color: "#fff",
    fontSize: "1rem",
    lineHeight: "2.5rem"
  },
  actionButton: {
    color: "#fff",
    textTransform: "none",
    border: "1px solid #fff",
    padding: "5px 20px",
    marginBottom: 10,
    fontSize: "1.5rem",
    lineHeight: 1.2,
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.25rem",
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
    },
  },
  iconButton: {
    color: "#fff",
    margin: "0 5px"
  },
  socialCallout: {
    color: "#fff",
    fontSize: "1rem",
    marginTop: 0
  },
  socialTitle:{
    fontSize: "1rem",
    display: "inline-block"
  },
  copyRight: {
    color: "#fff",
    fontSize: "1rem",
    textAlign: "center",
    marginTop: 60,
  },
  triangle: {
    position: "absolute",
    right: 0,
    bottom: 0,
    width: "30%",
  },
}))
