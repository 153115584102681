import * as React from "react"
import { Link, navigate } from "@reach/router"
import { Grid, Typography, Button } from "@material-ui/core"
import Icon from '@material-ui/core/Icon'
import LinkedInIcon from "@material-ui/icons/LinkedIn"
import EmailIcon from '@material-ui/icons/Email'
import IconButton from "@material-ui/core/IconButton"

import whiteLogoUrl from "../../../media/grandview-logo-white.png"
import triangleImgUrl from "../../../media/green-triangle-large.png"

import { useStyles } from "./styles"

const addressColList = [
  {
    headLogo: whiteLogoUrl,
    list: [
      {
        title: "500 West Madison Street",
      },
      {
        title: "Suite 1000",
      },
      {
        title: "Chicago, IL 60661",
      },
    ],
  },
]

const servicesColList = [
  {
    head: "Services",
    list: [
      {
        title: "Strategic Advisory",
        link: "/services/strategic-advisory",
      },
      {
        title: "Technology Implementation",
        link: "/services/technology-implementation",
      },
      {
        title: "System Integration",
        link: "/services/systems-integration",
      },
      {
        title: "Data and Analytics",
        link: "/services/data-and-analytics",
      },
    ],
  },
]

const technologyColList = [
  {
    head: "Technology",
    list: [
      {
        title: "Rivvit",
        link: "/technology",
      },
      {
        title: "Security Commitment",
        link: "/technology/security-commitment",
      },
    ],
  },
]

const learnMoreColList = [
  {
    head: "Learn More",
    list: [
      {
        title: "About Us",
        link: "/about-us",
      },
      {
        title: "Client Success Stories",
        link: "/client-work",
      },
      {
        title: "Careers",
        link: "/careers",
      },
      {
        title: "Privacy Policy",
        link: "/privacy-policy"
      }
    ],
  },
]

const contactColList = [
  {
    head: "Contact Us",
    list: [
      {
        title: "info@grandviewanalytics.com",
        type: "email",
      },
      {
        title: "(516) 286-7792",
        type: "phone",
      },
      {
        title: "Contact form",
        link: "/contact-us",
        type: "icon",
        icon: "email"
      },
      {
        title: "Follow us",
        link: "https://www.linkedin.com/company/grandview-analytics",
        type: "icon",
        icon: "linkedin"
      },
      
    ],
  },
]

const colList = [
  {
    title: "services",
    list: servicesColList,
  },
  {
    title: "technology",
    list: technologyColList,
  },
  {
    title: "learn more",
    list: learnMoreColList,
  },
]

const thisYear = new Date().getFullYear()
const copyRightText =
  "Copyright © 2014 - " +
  thisYear +
  " Grandview Analytics, LLC and Grandview Technology Solutions, LLC"

const Footer = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid
          item
          key={`footer-col-address`}
          xs={12}
          sm={12}
          md={12}
          lg={3}
          xl={3}
          className={classes.item}
        >
          {addressColList.map((lItem: any, lIndex: Number) => (
            <div
              key={`footer-address-col-${lIndex}`}
              className={classes.itemWrapper}
            >
              <img src={lItem.headLogo} className={classes.logo} />
              {lItem.list.map((llItem: any, llIndex: Number) => (
                <Typography
                  key={`footer-col-address-item-${llIndex}`}
                  variant="body2"
                  className={classes.footerText}
                >
                  {llItem.title}
                </Typography>
              ))}
            </div>
          ))}
        </Grid>
        {colList.map((item, index) => (
          <Grid
            item
            key={`footer-col-${item.title}`}
            xs={12}
            sm={6}
            md={6}
            lg={2}
            xl={2}
            className={classes.item}
          >
            {item.list.map((lItem: any, lIndex: Number) => (
              <div key={`footer-col-${lIndex}`} className={classes.itemWrapper}>
                <Typography variant="subtitle2" className={classes.footerHead}>
                  {lItem.head}
                </Typography>
                {lItem.list.map((llItem: any, llIndex: Number) => {
                  if (llItem.type !== "button") {
                    return (
                      <Link
                        key={`footer-col-${item.title}-item-${llIndex}`}
                        to={llItem.link}
                        className={classes.itemLink}
                      >
                        <Typography
                          variant="body2"
                          className={classes.footerText}
                        >
                          {llItem.title}
                        </Typography>
                      </Link>
                    )
                  } else if (llItem.type === "button") {
                    return (
                      <Button
                        key={`footer-col-${item.title}-item-${llIndex}`}
                        className={classes.actionButton}
                      >
                        {llItem.title}
                      </Button>
                    )
                  }
                })}
              </div>
            ))}
          </Grid>
        ))}
        <Grid
          item
          key={`footer-col-contact`}
          xs={12}
          sm={6}
          md={6}
          lg={3}
          xl={3}
          className={classes.item}
        >
          {contactColList.map((lItem: any, lIndex: Number) => (
            <div
              key={`footer-contact-col-${lIndex}`}
              className={classes.itemWrapper}
            >
              <Typography variant="subtitle2" className={classes.footerHead}>
                {lItem.head}
              </Typography>              
              {lItem.list.map((llItem: any, llIndex: Number) => {
                if (llItem.type === "icon") {
                  return (<div className={classes.socialCallout}>
                    <Typography className={classes.socialTitle}>{llItem.title}</Typography>
                    <IconButton
                      key={`footer-col-contact-item-${llIndex}`}
                      aria-label="Linkedin.com"
                      className={classes.iconButton}
                      onClick={() => navigate(llItem.link)}
                    >
                      {llItem.icon === "linkedin" && <LinkedInIcon fontSize="medium" />}
                      {llItem.icon === "email" && <EmailIcon fontSize="medium" />}
                    </IconButton>
                  </div>)
                } else if (llItem.type === "button") {
                  return (
                    <Button
                      key={`footer-col-contact-item-${llIndex}`}
                      onClick={() => navigate(llItem.link)}
                      className={classes.actionButton}
                    >
                      {llItem.title}
                    </Button>
                  )
                } else {
                  return (
                    <Typography
                      key={`footer-col-contact-item-${llIndex}`}
                      variant="body2"
                      className={classes.footerText}
                    >
                      {llItem.title}
                    </Typography>
                  )
                }
              })}
            </div>
          ))}
        </Grid>
      </Grid>
      <Typography variant="body2" className={classes.copyRight}>
        {copyRightText}
      </Typography>
      <img src={triangleImgUrl} className={classes.triangle} />
    </div>
  )
}

export default Footer
