import * as React from "react"
import {useEffect} from "react"
import { Link, navigate } from "@reach/router"
import {
  AppBar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar
} from "@material-ui/core"
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'
import * as Icon from "@material-ui/icons"

import logoUrl from "../../../media/grandview-logo.svg"

import { useStyles } from "./styles"


const isActive = (path: string, pathname: String) => {
  return (path === pathname).toString()
}

interface NavItemProps {
  to: string
  isactive: string
  text: string
}

const NavItem = React.forwardRef<NavItemProps, any>((props, ref) => {
  const { to, isactive, text } = props
  const classes = useStyles({ isactive })

  return (
    <MenuItem className={classes.navItem}>
      <Link to={to} className={classes.navLink}>
        {text}
      </Link>
    </MenuItem>
  )
})

interface HeaderProps {
  siteTitle: string
  location: {
    pathname: string
  }
}

const Header = ({ location: { pathname } }: HeaderProps) => {
  const classes = useStyles()
  const [
    mobileMoreAnchorEl,
    setMobileMoreAnchorEl,
  ] = React.useState<null | HTMLElement>(null)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget)
  }


  const mobileMenuId = "primary-menu-mobile"
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <NavItem
        to="/services"
        isactive={isActive(pathname, "/services")}
        text="Services"
      />
      <NavItem
        to="/technology"
        isactive={isActive(pathname, "/technology")}
        text="Technology"
      />
      <NavItem
        to="/client-work"
        isactive={isActive(pathname, "/client-work")}
        text="Client Work"
      />
      <NavItem
        to="/about-us"
        isactive={isActive(pathname, "/about-us")}
        text="About Us"
      />
      <NavItem
        to="/careers"
        isactive={isActive(pathname, "/careers")}
        text="Careers"
      />
      <NavItem
        to="/contact-us"
        isactive={isActive(pathname, "/contact-us")}
        text="Contact Us"
      />
    </Menu>
  )

  let isIE11 = false;
  useEffect(() => {
    isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
  }, []);

  return (
    <div className={classes.root}>

      {isIE11 &&
        <MuiAlert severity="warning" className={classes.alert}>
          This site does not support Internet Explorer 11. Please use a modern browser for an optimized experience.
        </MuiAlert>
      }

      <AppBar position="static">
        <Toolbar className={classes.toolbar}>
          <div className={classes.menuSection}>
            <Link className={classes.brand} to="/">
              <img src={logoUrl} alt="Logo" className={classes.logo} />
            </Link>
            <ul className={classes.navigation}>
              <NavItem
                to="/services"
                isactive={isActive(pathname, "/services")}
                text="Services"
              />
              <NavItem
                to="/technology"
                isactive={isActive(pathname, "/technology")}
                text="Technology"
              />
              <NavItem
                to="/client-work"
                isactive={isActive(pathname, "/client-work")}
                text="Client Work"
              />
              <NavItem
                to="/about-us"
                isactive={isActive(pathname, "/about-us")}
                text="About Us"
              />
              <NavItem
                to="/careers"
                isactive={isActive(pathname, "/careers")}
                text="Careers"
              />
            </ul>
          </div>
          <div className={classes.buttonSection}>
            {/* <Button className={classes.actionButton}>Log In</Button> */}
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/contact-us")}
              className={classes.contactUsButton}
            >
              Contact Us
            </Button>
          </div>
          <div className={classes.mobileMenuSection}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <Icon.Menu />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </div>
  )
}

export default Header
