import * as React from "react"

import { useStaticQuery, graphql } from "gatsby"
import { Container } from "@material-ui/core"

import Header from "../Header"
import Footer from "../Footer"
import { useStyles } from "./styles"

interface LayoutProps {
  children: object,
  location: {
    pathname: string
  }
}

const Layout = ({ children, location }: LayoutProps) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const classes = useStyles()



  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} location={location} />
      <Container maxWidth="xl" className={classes.root}>
        <main>{children}</main>
      </Container>
      <Footer />
    </>
  )
}

export default Layout
